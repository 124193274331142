import { Fragment } from "react";
import type { AppEnvironment } from "@inferno/renderer-shared-core";
import type { OnAirShowFragment } from "@ihr-radioedit/inferno-webapi";
import { placeholderImage } from "@ihr-radioedit/inferno-core";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { DATETIME_DISPLAY_FORMATS, zonedTimeFormat } from "@inferno/renderer-shared-core";
import { ScheduleLinkWrapper } from "../tiles/ScheduleLinkWrapper.component";
import { OnairScheduleThumb } from "./OnairScheduleThumb.component";

interface OnAirScheduleItemsProps {
  items: OnAirShowFragment[];
  hostname: string;
  stationLogo: string | null | undefined;
  env: AppEnvironment;
  timezone: string;
}

export const OnAirScheduleItems = ({ items, stationLogo, env, timezone }: OnAirScheduleItemsProps) => {
  return (
    <Fragment>
      {items.map((item: OnAirShowFragment, i) => {
        let imgUrl = formatImage(stationLogo || "", env.IMAGE_HOST) || placeholderImage;
        let name = item.name || "";
        let href: string | undefined;

        if (item.showSite?.configByLookup) {
          name = item.showSite?.configByLookup?.sections?.general?.name || "";
          const image = item.showSite?.configByLookup?.sections?.general?.thumbnail_image?.asset?.href;
          if (item.showSite?.slug) {
            href = `/featured/${item.showSite.slug}/`;
          }
          if (image) {
            imgUrl = image;
          }
        }

        const formattedStart = zonedTimeFormat({
          date: item.startMs,
          timezone,
          outputFormat: DATETIME_DISPLAY_FORMATS.TWELVE_HOUR_UNPADDED,
        });

        const formattedEnd = zonedTimeFormat({
          date: item.stopMs,
          timezone,
          outputFormat: DATETIME_DISPLAY_FORMATS.TWELVE_HOUR_UNPADDED,
        });

        const start24 = zonedTimeFormat({
          date: item.startMs,
          timezone,
          outputFormat: DATETIME_DISPLAY_FORMATS.TWENTYFOUR_HOUR_PADDED,
        });

        const end24 = zonedTimeFormat({
          date: item.stopMs,
          timezone,
          outputFormat: DATETIME_DISPLAY_FORMATS.TWENTYFOUR_HOUR_PADDED,
        });
        return (
          <li key={i}>
            <figure className="onair-schedule-item">
              <section className="thumb">
                <ScheduleLinkWrapper href={href}>
                  <OnairScheduleThumb alt={name} imgUrl={imgUrl} />
                </ScheduleLinkWrapper>
              </section>
              <figcaption>
                <section className="show-title">
                  <ScheduleLinkWrapper href={href}>{name}</ScheduleLinkWrapper>
                </section>
                <section className="show-time">
                  <time data-output="start-time" dateTime={start24}>
                    {formattedStart}
                  </time>
                  &nbsp;-&nbsp;
                  <time data-output="end-time" dateTime={end24}>
                    {formattedEnd}
                  </time>
                </section>
              </figcaption>
            </figure>
          </li>
        );
      })}
    </Fragment>
  );
};
